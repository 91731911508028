const config = {
  version: '0.7.27',
  production: true,
  authenticationType: 'ActiveDirectory',
  msalModule: {
    clientID: '94df3618-e9ec-4ce8-8eaf-a7d97d46164f',
    authority: 'https://login.microsoftonline.com/3333b860-8fed-4146-aaeb-682401d60e10/',
    redirectUri: '/auth',
    appScope: 'api://94df3618-e9ec-4ce8-8eaf-a7d97d46164f/user_impersonation',
    showDetailedAuthLogs: false,
  },
  urlMappings: [
    {
      client: 'https://engine.onarp.cloud',
      server: 'https://engine-server.onarp.cloud',
    },
    {
      client: 'https://engine.dev.local',
      server: 'https://engine-server.dev.local',
    },
    {
      client: 'https://rc.onengine.cloud',
      server: 'https://rc-server.onengine.cloud',
    },
    {
      client: 'https://rc.dev.local',
      server: 'https://rc-server.dev.local',
    },
    {
      client: 'https://arp-engine-0-1.azurewebsites.net',
      server: 'https://arp-engine-0-1-server.azurewebsites.net',
    },
    {
      client: 'http://localhost:4200',
      server: 'http://localhost:58658',
    },
    {
      client: 'https://licensing.dev.local',
      server: 'https://licensing-server.dev.local',
    },
    {
      client: 'https://licensing.onarp.cloud',
      server: 'https://licensing-server.onarp.cloud',
    },
    {
      client: 'https://crm.onarp.cloud',
      server: 'https://crm-server.onarp.cloud',
    },
    {
      client: 'https://enginegreen.dev.local',
      server: 'https://enginegreen-server.dev.local',
    },
  ],
};
